const Ajv = require('ajv').default
const ajv = new Ajv({ allErrors: true })
require('ajv-errors')(ajv, { singleError: true }
)
require('ajv-keywords')(ajv, ['transform'])

export const editTCValidator = (state, initialState) => {
  const isSame = state.taggedMRs.length === initialState.taggedMRs.length && state.taggedMRs.every((item, index) => item.value === initialState.taggedMRs[index].value)

  const dataFormatter = {
    ...(state.feederId !== initialState.feederId && {
      feeder: {
        id: state.feederId
      }
    }),
    ...(state.taggedMRs.length > 0 && !isSame && {
      taggedMRs: state.taggedMRs.map(item => {
        return ({ sub: item.value })
      })
    }),
    ...(state.readingMR !== initialState.readingMR && {
      readingMR: {
        sub: state.readingMR
      }
    }),
    ...(state.tcCapacity !== initialState.tcCapacity && { capacity: Number(state.tcCapacity) }),
    ...(state.executionType !== initialState.executionType && { executionType: state.executionType }),
    ...(state.readingDay !== initialState.readingDay && { readingDay: Number(state.readingDay) }),
    ...(state.tcName !== initialState.tcName && { name: state.tcName }),
    ...(state.tcNumber !== initialState.tcNumber && { number: state.tcNumber }),
    ...(state.timsCode !== initialState.timsCode && { timsCode: state.timsCode }),
    ...(state.dtlmsCode !== initialState.dtlmsCode && { dtlmsCode: state.dtlmsCode }),
    ...(state.dtrCode !== initialState.dtrCode && { dtrCode: state.dtrCode }),
    ...(state.tcMake !== initialState.tcMake && { make: state.tcMake }),
    ...(state.tcSerialNumber !== initialState.tcSerialNumber && { serialNumber: state.tcSerialNumber }),
    ...((state.gpsCoordinates.latitude !== initialState.gpsCoordinates.latitude || state.gpsCoordinates.longitude !== initialState.gpsCoordinates.longitude) && {
      gpsCoordinates: {
        coordinates: {
          ...(state.gpsCoordinates.latitude !== initialState.gpsCoordinates.latitude &&
                        { lat: Number(state.gpsCoordinates.latitude) }),
          ...(state.gpsCoordinates.longitude !== initialState.gpsCoordinates.longitude && { long: Number(state.gpsCoordinates.longitude) })
        }
      }
    }),
    ...((state.meterMake !== initialState.meterMake || state.meterSerialNumber !== initialState.meterSerialNumber || state.primaryCurrent !== initialState.primaryCurrent || state.secondaryCurrent !== initialState.secondaryCurrent) && {
      meter: {
        ...(state.meterMake !== initialState.meterMake && { make: state.meterMake }),
        ...(state.meterSerialNumber !== initialState.meterSerialNumber && { serialNumber: state.meterSerialNumber }),
        ...((state.primaryCurrent !== initialState.primaryCurrent || state.secondaryCurrent !== initialState.secondaryCurrent) && {
          ctRatio: {
            ...({ primaryCurrent: Number(state.primaryCurrent) }),
            ...({
              secondaryCurrent: Number(state.secondaryCurrent)
            })
          }
        })
      }
    })
    // ...(((state.initialReading || state.initialReadingRemarks) && state.initialReadingMonth) && {
  }

  // meter,gps

  // console.log(state);

  const schema = {
    title: 'AddTC',
    description: 'Create transformer-centre object',
    type: 'object',
    // required: ['feeder', 'readingDay', 'name', 'number', 'taggedMRs', 'readingMR',],
    // additionalProperties: false,

    properties: {
      taggedMRs: {
        type: 'array',
        minLength: 1,
        errorMessage: {
          minLength: 'Please Select a reading MR'
        }

      },

      readingMR: {
        type: 'object',
        required: ['sub'],
        additionalProperties: false,
        properties: {
          type: { type: 'string' },
          sub: {
            type: 'string',
            minLength: 1,
            additionalProperties: false,
            errorMessage: {
              minLength: 'Please Select a reading MR'
            }
          }
        }

      },

      number: {
        type: 'string',
        transform: ['trim'],
        minLength: 1,
        maxLength: 10,
        pattern: '^[1-9][0-9]*$',
        errorMessage: {
          type: 'Please enter numeric values for TC Number',
          minLength: 'Please enter TC Number',
          maxLength: 'Please enter less than 10 characters for TC Number',
          pattern: 'TC Number should be digits and should not begin with 0'
        }
      },
      name: {
        type: 'string',
        transform: ['trim'],
        minLength: 3,
        maxLength: 500,
        errorMessage: {
          type: 'Please enter TC Name',
          minLength: 'Please enter more than 2 characters for TC Name',
          maxLength: 'Please enter less than 500 characters for TC Name'
        }
      },
      readingDay: {
        type: 'integer',
        enum: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15],
        errorMessage: {
          type: 'Please select Reading Day',
          enum: 'Please enter a valid Reading Day'
        }
      },
      timsCode: {
        type: 'string',
        transform: ['trim'],
        minLength: 3,
        maxLength: 30,
        errorMessage: {
          type: 'Please enter numeric values for TIMS Code',
          minLength: 'Please enter more than 3 characters for TIMS Code',
          maxLength: 'Please enter less than 30 characters for TIMS Code'
        }
      },
      dtlmsCode: {
        type: 'string',
        transform: ['trim'],
        minLength: 3,
        maxLength: 30,
        errorMessage: {
          type: 'Please enter numeric values for DTLMS Code ',
          minLength: 'Please enter more than 3 characters for DTLMS Code',
          maxLength: 'Please enter less than 30 characters for DTLMS Code'
        }
      },
      dtrCode: {
        type: 'string',
        transform: ['trim'],
        minLength: 3,
        maxLength: 30,
        errorMessage: {
          type: 'Please enter numeric values for DTR Code ',
          minLength: 'Please enter more than 3 characters for DTR Code',
          maxLength: 'Please enter less than 30 characters for DTR Code'
        }
      },
      make: {
        type: 'string',
        transform: ['trim'],
        minLength: 3,
        maxLength: 30,
        errorMessage: {
          type: 'Please enter values for TC Make ',
          minLength: 'Please enter more than 3 characters for TC Make',
          maxLength: 'Please enter less than 30 characters for TC Make'
        }
      },
      serialNumber: {
        type: 'string',
        transform: ['trim'],
        // pattern: '^[a-zA-Z0-9]+$',
        minLength: 3,
        maxLength: 30,
        errorMessage: {
          type: 'Please enter values for TC Serial Number',
          minLength: 'Please enter more than 3 characters for TC Serial Number',
          maxLength: 'Please enter less than 30 characters for TC Serial Number',
          pattern: 'Please enter TC Serial Number only with string and number pattern'
        }
      },
      capacity: {
        type: 'integer',
        enum: [25, 63, 100, 160, 250, 315, 500, 990],
        errorMessage: {
          type: 'Please select TC Capacity',
          enum: 'Please select a valid TC Capacity'
        }
      },
      executionType: {
        type: 'string',
        enum: ['self', 'department'],
        errorMessage: {
          type: 'Please select Execution Type',
          enum: 'Please select a valid Execution Type'
        }
      },
      gpsCoordinates: {
        type: 'object',
        required: ['coordinates'],
        additionalProperties: false,
        properties: {
          type: { type: 'string', const: 'point' },
          coordinates: {
            type: 'object',
            required: ['lat', 'long'],
            errorMessage: {
              required: 'Please provide GPS co-ordinates'
            },
            additionalProperties: false,
            properties: {
              lat: { type: 'number', minimum: -90, maximum: 90 },
              long: {
                type: 'number',
                minimum: -180,
                maximum: 180
              }
            }
          }
        }
      },
      feeder: {
        type: 'object',
        required: ['id'],
        errorMessage: {
          required: 'Please select Feeder'
        },
        additionalProperties: false,
        properties: {
          id: {
            type: 'string',
            pattern: '^[A-Za-z0-9-_]+$',
            errorMessage: {
              pattern: 'Please select a feeder'
            }
          }
        }
      },
      meter: {
        type: 'object',
        // required: ['ctRatio'],
        additionalProperties: false,
        properties: {
          make: {
            type: 'string',
            transform: ['trim'],
            minLength: 3,
            maxLength: 30,
            errorMessage: {
              type: 'Please enter Meter Make details',
              minLength: 'Please enter more than 3 characters for Meter Make',
              maxLength: 'Please enter less than 30 characters for Meter Make'
            }
          },
          serialNumber: {
            type: 'string',
            transform: ['trim'],
            // pattern: '^[0-9]',
            minLength: 3,
            maxLength: 30,
            errorMessage: {
              type: 'Please enter a valid Serial Number',
              minLength: 'Please enter more than 3 characters for Meter Serial Number',
              maxLength: 'Please enter less than 30 characters for Meter Serial Number',
              pattern: 'Please enter Serial Number only with string and number pattern'
            }
          },
          ctRatio: {
            type: 'object',
            // required: ['primaryCurrent', 'secondaryCurrent'],
            additionalProperties: false,
            properties: {
              primaryCurrent: {
                type: 'integer',
                minimum: 1,
                errorMessage: {
                  type: 'Please enter Number values for Primary Ratio ',
                  minimum: 'Please enter positive number values for Primary Ratio '
                }
              },
              secondaryCurrent: {
                type: 'integer',
                minimum: 1,
                errorMessage: {
                  type: 'Please enter Number values for Secondary Ratio ',
                  minimum: 'Please enter positive number values for Secondary Ratio '
                }
              }
            }
          }
        }
      }

    }
    //
  }

  const validation = ajv.compile(schema)
  const isValid = validation(dataFormatter)
  if (isValid) return dataFormatter
  throw (validation.errors[0])
}
