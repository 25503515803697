import React from 'react'
import styles from './loadMoreButton.module.scss'

const LoadMoreButton = ({ onClick }) => {
  return (
    <div className={styles.buttonContainer} onClick={onClick}>
      <div className={styles.button}>Load More...</div>
    </div>
  )
}

export default LoadMoreButton
