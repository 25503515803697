import React, { useState, useEffect, useContext } from 'react'
import styles from './mrOnboardingFileHistory.module.scss'
import FileStatus from '../../../../components/molecules/FileStatus/FileStatus'
import FileHistoryHeader from '../../../../components/molecules/FileHistoryHeader/FileHistoryHeader'
import { NotifierContext } from '../../../../context/NotifierContext'
import { getUploadStatus, getDownLoadProcessedResultUrl } from '../../../../services/api/tcAuditService'
import LoadMoreButton from '../../../../components/atoms/LoadMoreButton/LoadMoreButton'
import Modal from '../../../../components/atoms/Modal/Modal'
import Filter from '../../../Shared/Filter/Filter'
import { getFileHistoryFilterOptions } from '../../../../assets/offlineData/fileHistoryFilterOptions'

const MrOnboardingFileHistory = () => {
  const [loading, setLoading] = useState(true)
  const [processedResultLoader, setProcessedResultLoader] = useState(false)
  const [filesHistory, setFilesHistory] = useState([])
  const [modalVisible, setModalVisibility] = useState(false)
  const [isFilterVisible, setFilterVisibility] = useState(false)
  const [filters, setFilters] = useState({
    uploadedBy: '',
    fileStatus: ''
  })
  const [pageData, setPageData] = useState({
    current: 1,
    last: 1
  })
  const { notifyError } = useContext(NotifierContext)

  useEffect(() => {
    fetchFileUploadStatus(1, filters.uploadedBy, filters.fileStatus)
  }, [])

  const setFileStatusFilter = (selectedValue) => {
    setFilters(prevValue => ({
      ...prevValue,
      fileStatus: prevValue.fileStatus === selectedValue ? '' : selectedValue
    }))
  }

  const setUploadedByFilter = (selectedValue) => {
    setFilters(prevValue => ({
      ...prevValue,
      uploadedBy: selectedValue
    }))
  }

  const handleFilterRemoval = (filterName, filterValue) => {
    if (filterName === 'fileStatus') {
      setFileStatusFilter('')
      fetchFileUploadStatus(1, filters.uploadedBy, '')
    }
    if (filterName === 'uploadedBy') {
      setUploadedByFilter('')
      fetchFileUploadStatus(1, '', filters.fileStatus)
    }
  }

  const filterOptions = getFileHistoryFilterOptions(filters.fileStatus, setFileStatusFilter, filters.uploadedBy, setUploadedByFilter)

  const fetchFileUploadStatus = async (page, uploadedBy, fileStatus) => {
    try {
      setLoading(true)
      const response = await getUploadStatus({
        fileCategory: 'users',
        page,
        perPage: 10,
        sortBy: 'uploadDate',
        ...(uploadedBy !== '' && { uploadedBy }),
        ...(fileStatus !== '' && { fileStatus })
      })
      if (page === 1) {
        setFilesHistory(response.data.fileHistory)
      } else {
        setFilesHistory([...filesHistory, ...response.data.fileHistory])
      }
      setPageData(response.data.pageData)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleLoadMore = () => {
    const nextPage = pageData.current + 1
    fetchFileUploadStatus(nextPage, filters.uploadedBy, filters.fileStatus)
  }

  const handleProcessingResultDownload = async (fileId) => {
    try {
      setProcessedResultLoader(true)
      const response = await getDownLoadProcessedResultUrl('users', fileId)
      window.location.href = response.data
    } catch (error) {
      notifyError(error)
    } finally {
      setProcessedResultLoader(false)
    }
  }

  const handleFilterApply = () => {
    fetchFileUploadStatus(1, filters.uploadedBy, filters.fileStatus)
    setFilterVisibility(false)
  }

  const handleFilterReset = () => {
    fetchFileUploadStatus(1, '', '')
    setFilters({ uploadedBy: '', fileStatus: '' })
    setFilterVisibility(false)
  }

  return (
    <div className='container'>
      <Modal
        title={`Possible reasons for ${modalVisible} rows`}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        hideFooter
      >
        {modalVisible === 'failure' &&
          (
            <>
              1. When the parent is not found
              <br />
              {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}Eg - They are trying to create an AEE whose parent EE does not exist
              <br /><br />
              2. When the user provides a duplicate phone number
              <br /> <br />
              3. For a MR, if the MR code does not begin with the sub-division code, it goes to failure state
              <br /> <br />
            </>
          )}
        {modalVisible === 'skipped' &&
          <>
            1. Rows are skipped when there are duplicate rows i.e., when a code for the given designation is already present
            <br /><br />
            2. Rows are skipped when the user is already present in the system and there are no changes in the editable columns for that user
            <br /><br />
            3. When an AE has uploaded the file and is trying to create MR for other sections
            <br /> <br />
          </>}
      </Modal>
      <FileHistoryHeader
        title='MR Onboarding'
        onRefresh={() => fetchFileUploadStatus(1, filters.uploadedBy, filters.fileStatus)}
        loading={loading}
        onClickFilter={() => setFilterVisibility(true)}
        filtersApplied={filters}
        onFilterRemoval={handleFilterRemoval}

      />
      <div className='mt-3'>
        {loading
          ? <div>Loading....</div>
          : filesHistory.length === 0
            ? <div>No results found!</div>
            : (
              <>
                {filesHistory.map((file, index) => {
                  return (
                    <div className={styles.fileStatusContainer} key={file.fileId}>
                      <FileStatus
                        uploadedBy={file.uploadedBy}
                        index={index + 1}
                        status={file.status}
                        fileId={file.fileId}
                        uploadedOn={file.uploadedOn}
                        message={file.message}
                        onProcessingResultDownload={() => { handleProcessingResultDownload(file.fileId) }}
                        disableProcessedResult={processedResultLoader}
                        count={{
                          success: file.count?.success ?? '-',
                          failure: file.count?.failure ?? '-',
                          skipped: file.count?.skipped ?? '-'
                        }}
                        onClickFailureRows={() => setModalVisibility('failure')}
                        onClickSkippedRows={() => setModalVisibility('skipped')}
                      />
                    </div>
                  )
                })}
                {!(pageData.current === pageData.last) &&
                  <LoadMoreButton onClick={handleLoadMore} />}
              </>
              )}

      </div>
      <Filter
        isFilterVisible={isFilterVisible}
        onFilterClose={() => { setFilterVisibility(false) }}
        onFiltersApply={handleFilterApply}
        onResetApply={handleFilterReset}
        filterData={filterOptions}
      />
    </div>
  )
}

export default MrOnboardingFileHistory
