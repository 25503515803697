import React, { createContext, useEffect, useState } from 'react'
import { DateToYYYYMM } from '../services/utils/date'

export const AuditMonthContext = createContext()

const AuditMonthProvider = ({ children }) => {
  const [auditMonth, setAuditMonthYear] = useState(new Date(new Date().getFullYear(), new Date().getMonth() - 1))
  const [mrCode, setMrCode] = useState([])

  useEffect(() => {
    const initialMonth = window.localStorage.getItem('auditMonth')
    setAuditMonthYear(initialMonth ? new Date(initialMonth) : new Date())
  }, [])

  const auditMonthHandler = (auditMonth) => {
    setAuditMonthYear(auditMonth)
    window.localStorage.setItem('auditMonth', auditMonth)
  }

  return (
    <AuditMonthContext.Provider value={{
      auditMonth,
      setAuditMonth: auditMonthHandler,
      auditMonthYear: DateToYYYYMM(auditMonth),
      mrCode,
      setMrCode
    }}
    >
      {children}
    </AuditMonthContext.Provider>
  )
}

export default AuditMonthProvider
