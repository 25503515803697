import React, { useContext } from 'react'
import NoDataFound from '../../../components/atoms/NoDataFound'
import { AuditMonthContext } from '../../../context/AuditMonthContext'

const InstallationsTable = ({ loading, installations, perPage, pageData, selected }) => {
  const { auditMonth } = useContext(AuditMonthContext)

  const getMetaData = (installation) => {
    const installationWithAudit = installation.audit ?? []
    const metaData = installationWithAudit.filter(item => (
      new Date(item.month).getMonth() === auditMonth.getMonth() &&
            new Date(item.month).getFullYear() === auditMonth.getFullYear()
    ))

    return {
      eMax: metaData[0]?.billing?.EMax ?? '----',
      eMin: metaData[0]?.billing?.EMin ?? '----',
      demand: metaData[0]?.billing?.demand ?? '----',
      collection: metaData[0]?.billing?.collection ?? '----',
      billCancellation: metaData[0]?.billing?.billCancellation ?? '----',
      consumption: metaData[0]?.billing?.consumption ?? '----'

    }
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <th className='ps-4' scope='col'>
            #
          </th>
          <th scope='col'>MR Code</th>
          <th scope='col'>Reading Day</th>
          <th scope='col'>Account ID</th>
          <th scope='col'>RR Number</th>
          <th scope='col'>Tariff</th>
          <th scope='col'>Consumer Name</th>
          <th scope='col'>Sanctioned Load</th>
          <th scope='col'>Consumption</th>
          {/* <th scope="col">MD</th> */}
          <th scope='col'>Bill Amount</th>
          {
           selected === 'subnormal' &&
             <th scope='col'>Ex-min Consumption</th>
          }
          {
            selected === 'abnormal' &&
              <th scope='col'>Ex-max Consumption</th>
          }
          {
            selected === 'billCancellation' &&
              <>
                <th scope='col'>Bill Cancellation</th>
                <th scope='col'>Collection</th>
              </>
          }
        </tr>
      </thead>
      <tbody>
        {
          loading
            ? <NoDataFound colSpan={12} message='Loading...' />
            : installations.length === 0
              ? <NoDataFound colSpan={12} message='No Installations found!' />
              : installations.map((installation, index) => {
                return (
                  <tr key={index}>
                    <td className='ps-4' scope='row'>{((pageData.currentPage - 1) * perPage) + (index + 1)}</td>
                    <td>{installation?.MR?.mrCode ?? '----'}</td>
                    <td>{installation.readingDay || '----'}</td>
                    <td>{installation.accountId ?? '----'}</td>
                    <td>{installation.RRNumber ?? '----'}</td>
                    <td>{installation.tariff?.short ?? '----'}</td>
                    <td style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100px' }}>{installation?.consumerName ?? 'N/A'}</td>
                    <td>{installation?.sanctionedLoad?.kW ?? 'N/A'} KW, {installation?.sanctionedLoad?.hp ?? '--'} HP</td>
                    <td style={{ textAlign: 'center' }}>{getMetaData(installation).consumption}</td>
                    {/* <td style={{ textAlign: "center" }}>{installation.MD ?? "----"}</td> */}
                    <td style={{ textAlign: 'center' }}>₹{getMetaData(installation).demand.toLocaleString('en-IN')}</td>
                    {
                      selected === 'subnormal' &&
                        <td style={{ textAlign: 'center' }}>{getMetaData(installation).eMin}</td>
                    }
                    {selected === 'abnormal' &&
                      <td style={{ textAlign: 'center' }}>{getMetaData(installation).eMax}</td>}
                    {
                       selected === 'billCancellation' &&
                         <>
                           <td style={{ textAlign: 'center' }}>{getMetaData(installation).billCancellation}</td>
                           <td style={{ textAlign: 'center' }}>{getMetaData(installation).collection}</td>
                         </>
                     }
                  </tr>
                )
              })
        }
      </tbody>
    </table>
  )
}

export default InstallationsTable
