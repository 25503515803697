import React from 'react'
import Button from '../../../components/atoms/Button'
import { Colors } from '../../../theme/colors'
import { checkIfUserIsAE } from '../../../services/utils/meta'

const BillingTabSearch = ({
  MRs = [],
  selectedMR = '',
  setSelectedMR = () => {},
  selectedReadingDay = '',
  setSelectedReadingDay = () => {},
  onClear = () => {},
  showClearButton = false,
  onSearch = () => {}
}) => {
  const isUserAE = checkIfUserIsAE()

  return (
    <div className='search-form'>
      <div className='forms-input'>
        <div className='row'>
          <div className='col-12 col-md-5 col-lg-4'>
            <div
              className={`search-space position-relative me-auto ${
                isUserAE ? 'd-block' : 'd-none'
              }`}
            >
              <select
                className='form-select'
                aria-label='Default select example'
                value={selectedMR}
                onChange={(option) => setSelectedMR(option.target.value)}
              >
                <option value=''>Enter MR Code</option>
                {MRs.map((item, index) => {
                  return (
                    <option
                      value={`${item}`}
                      key={String(item)}
                    >
                      {item}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-4 reading-day-input'>
            <div className='search-space position-relative me-auto'>
              <select
                className='form-select'
                aria-label='Default select example'
                value={selectedReadingDay}
                onChange={(option) => setSelectedReadingDay(option.target.value)}
              >
                <option value=''>Select a Reading Day</option>
                {[1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15].map((item, index) => {
                  return (
                    <option
                      value={`${item}`}
                      key={String(item)}
                    >
                      {item}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-12 col-md-3 col-lg-2'>
            <Button
              onClick={onSearch}
              style={{ background: Colors.primaryColor }}
            >
              Search
            </Button>
          </div>
          {showClearButton && (
            <div className='col-12 col-md-3 col-lg-2'>
              <Button
                style={{
                  // width: "60px",
                  // height: "30px",
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  borderRadius: 30,
                  background: Colors.secondaryColor
                }}
                onClick={onClear}
              >
                Clear X
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BillingTabSearch
